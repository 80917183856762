<template>
    <div class="animated fadeIn">
        <div id="snackbar"></div>
        <b-card>
          <b-row>
                <b-col sm="4">
                   <b-form-group>
                      <b-form-select required v-model="organization.selectOrganization" id="cinemas"
                        :plain="true"
                        value-field="OrganizationID"
                        text-field="OrganizationName"
                        :options=organization.organizationList
                        @change.native="onChangeOrganizationDropdwon($event.target.value)"
                        >
                        <template slot="first">
                          <option :value="'0'" disabled>-- Select Organization --</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="4">
                    <b-form-group>
                        <label for="date">From Date <span class="text-danger">*</span></label>
                        <b-form-input @change.native="onChangeCinemaDropdwon($event.target.value,'date')" type="date" v-model="filtersObjects.fromDate" required  id="id"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label for="date">To Date <span class="text-danger">*</span></label>
                        <b-form-input @change.native="onChangeCinemaDropdwon($event.target.value,'date')" v-model="filtersObjects.toDate" type="date" required id="id"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4">
                    <b-form-group>
                      <b-form-select required v-model="cinemasObject.selectedCinema" id="cinemas"
                        :plain="true"
                         value-field="CinemaID"
                        text-field="CinemaName"                       
                        :options=cinemas
                        @change.native="onChangeCinemaDropdwon($event.target.value,'cinema')"
                        >
                        <template slot="first">
                          <option :value="'0'" disabled>-- Select Cinema --</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col v-if="checkPermission" sm="4">
                    <b-form-select required v-model="cinemasObject.selectedMovie" id="cinemas"
                        :plain="true"
                          value-field="MovieID"
                        text-field="Title"
                        :options=cinemasObject.moviesList
                        @change.native="onChangeCinemaDropdwon($event.target.value,'movie')"
                        >
                        <template slot="first">
                          <option :value="'0'" disabled>-- Select Movie --</option>
                        </template>
                      </b-form-select>
                    <!-- <b-form-group>
                        <label for="bankName">Movie Name</label>
                        <b-form-input type="text" v-model="filtersObjects.movieName" placeholder="Movie Name"></b-form-input>                
                    </b-form-group> -->
                </b-col>
                <b-col sm="4">
                   <b-form-group>
                        <label for=""></label>
                        <b-button v-on:click="onFilterSubmit()" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                    </b-form-group>
                </b-col> 
            </b-row>
            
            <!-- <b-form-group>
                <label for="cinemas">Cinema <span class="text-danger">*</span></label>
                <b-form-select required v-model="CinemaID" id="cinemas"
                  :plain="true"
                  :options=cinemas
                  @change.native="selectCinema($event.target.value)"
                  >
                </b-form-select>
              </b-form-group> -->
            <b-card-header>
              <b-row>
                <b-col>
                  Finance Report
                </b-col>                
                <b-col class="col d-flex justify-content-end">       
                    <b-button v-if="data.length > 0" v-on:click="onDownloadPDF('PDF')" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i>Download as PDF</b-button>&nbsp;&nbsp;
                    <b-button v-if="data.length > 0" v-on:click="onDownloadExcel('XLSX')" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i>Download as XLSX</b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
                <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
                    <!-- <div slot="ShowID" slot-scope="props">
                        <b-form v-on:submit.prevent.self="submit(props.row.ShowID)" :id="props.row.ShowID">
                            <b-col sm="6">
                                <b-form-group>
                                    <label for="transferID">Transfer ID <span class="text-danger">*</span></label>
                                    <b-form-input  type="number" step="any" required v-model="transferID[props.row.ShowID]" placeholder="Transfer ID"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6">
                                <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                            </b-col>
                        </b-form>
                    </div> -->
                </v-client-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName,WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "Admin-Finance-Report",
  components: {
    Event,
    cSwitch
  },
  data() {
    return {
      checkPermission: false,
      // Saif
      filtersObjects: {
        fromDate: "",
        toDate: "",
        movieName: "",
        cinemaName: ""
      },
      cinemasObject: {
        selectedCinema: "0",
        selectedMovie: "0",
        moviesList: [],
        fileXlsxLink: "",
        filePDFLink: ""
      },

      organization: {
        organizationList: [],
        selectOrganization: "0"
      },

      // Saif

      transferID: [],
      CinemaID: 0,
      cinemas: [],
      columns: [
        "Organization",
        "MovieName",
        "CinemaName",
        "NoOfTickets",
        "TotalRevenue",
        "ConvenienceFee",
        "TotalAmount",
        "Date"
      ],
      data: [],
      options: {
        headings: {
          Organization: "Organization",
          MovieName: "Movie Name",
          CinemaName: "Cinema Name",
          NoOfTickets: "No Of Tickets",
          TotalRevenue: "Total Revenue",
          ConvenienceFee: "Convenience Fee",
          TotalAmount: "Total Amount",
          Date: "Date"
        },
        sortable: [
          "Organization",
          "MovieName",
          "CinemaName",
          "NoOfTickets",
          "TotalRevenue",
          "ConvenienceFee",
          "TotalAmount",
          "Date"
        ],
        filterable: [
          "Organization",
          "MovieName",
          "CinemaName",
          "NoOfTickets",
          "TotalRevenue",
          "ConvenienceFee",
          "TotalAmount",
          "Date"
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  beforeMount() {
    if (
      localStorage.getItem("UserRole") !== undefined &&
      localStorage.getItem("UserRole") !== undefined
    ) {
      this.checkPermission =
        localStorage.getItem("UserRole") == "Admin" ? true : false;
      if (localStorage.getItem("UserRole") == "Admin") {
        this.getMovies();
      }
    }
  },
  mounted() {
    this.getOrganization();
    this.getCinemasDDL(0);
    this.onFilterSubmit();
  },
  methods: {
    // Saif
    onChangeOrganizationDropdwon: function(id) {
      this.getCinemasDDL(id);
    },

    onDownloadExcel: function(type) {
      if (this.data.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "ADMIN_FINANCE"); // sheetAName is name of Worksheet
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_ADMIN_FINANCE_REPORT_${new Date().toDateString()}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onDownloadPDF: function(type) {
      if (this.data.length > 0) {
        let mainArray = [];
        this.data.map(x => {
          let tempArray = [];
          this.columns.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          mainArray.push(tempArray);
        });
        var imgData = BASE64IMAGE;

        var doc = new jsPDF("l", "px", [1000, 600]);

        doc.addImage(imgData, "JPEG", 280, 0, 150, 100);
        doc.text(
          260,
          100,
          `${WANASHAdashboardName.toUpperCase()} ADMIN FINANCE REPORT`
        );
        doc.setFontSize(17);

        doc.autoTable({
          head: [this.columns],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: mainArray,
          margin: { top: 120 },
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.save("ADMIN_FINANCE_REPORT_" + new Date().toDateString() + ".pdf");
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getOrganization: function() {
      this.organization.organizationList = [];
      // let res = await CinemaService.getOrganization();
      this.organizations = [];
      CinemaService.getOrganization()
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organization.organizationList = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onChangeCinemaDropdwon: function(cinema, name) {
      console.log("Herer data", cinema, name);
      this.cinemasObject.fileXlsxLink = "";
      this.cinemasObject.filePDFLink = "";

      if (name == "cinema") {
        this.cinemasObject.selectedCinema = cinema;
      } else if (name == "movie") {
        this.cinemasObject.selectedMovie = cinema;
      }
    },

    getMovies: function() {
      this.cinemasObject.moviesList = [];
      // let res = await MovieService.getMoviesDDL();

      MovieService.getMoviesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.MovieList.length > 0) {
            this.cinemasObject.moviesList = data.MovieList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onFilterSubmit: function() {
      this.data = [];
      // if (
      //   this.filtersObjects.fromDate !== "" &&
      //   this.filtersObjects.toDate !== ""
      // ) {
      // console.log("insideif", this.filtersObjects, this.cinemasObject);
      let payload = {
        OrgID:
          this.organization.selectOrganization == "0"
            ? ""
            : this.organization.selectOrganization,
        fromDate: this.filtersObjects.fromDate,
        toDate: this.filtersObjects.toDate,
        cinemaId:
          this.cinemasObject.selectedCinema == "0"
            ? ""
            : this.cinemasObject.selectedCinema,
        movieId:
          this.cinemasObject.selectedMovie == "0"
            ? ""
            : this.cinemasObject.selectedMovie
      };
      // let res = await CinemaService.getAdminFinance(payload);
      CinemaService.getAdminFinance(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.FinanceReportList.length > 0) {
            this.data = data.FinanceReportList;
            this.cinemasObject.fileXlsxLink = data.ExcelUrl;
            this.cinemasObject.filePDFLink = data.PDFUrl;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
      // } else {
      // this.showMessageSnackbar("Please Select dates");
      // }
    },

    getCinemasDDL: function(OrgID) {
      this.cinemas = [];
      // let res = await CinemaService.getCinemasDDL(id);
      let payload = {
        OrgID
      };
      CinemaService.getCinemasDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemas = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    submit: function(ShowID) {
      let body = {
        TransferID: this.transferID[ShowID],
        ShowID
      };
      // let res = await CinemaService.setTransferID(body);

      CinemaService.setTransferID(body)
        .then(response => {
          const { data } = response;
          if (data.Status && data.Userrole.length > 0) {
            this.showMessageSnackbar(data.Data.TransferID);
            this.data.splice(ShowID - 1, 1);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    selectCinema: function(CinemaID) {
      // let res = await CinemaService.getAdminFinance(CinemaID);
      // if (res.data.Status == true) {
      //   this.data = res.data.Data;
      // }
      // CinemaService.getAdminFinance(CinemaID)
      //   .then(response => {
      //     const { data } = response;
      //     if (data.Status && data.Data.length > 0) {
      //       this.data = data.Data;
      //     } else {
      //       this.showMessageSnackbar(data.Message);
      //     }
      //   })
      //   .catch(error => {
      //     this.showMessageSnackbar(
      //       "Oops! Something went wrong. kindly contact your administrator"
      //     );
      //   });
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
